import React from 'react'
import { useEffect, useState } from 'react'
import { Location, LocationContext, WindowLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import TypographyLink from 'src/components/Typography/Link'
import NavLink from 'src/components/NavLink'
import Wrapper from './style'
import GetNewsNotification from 'src/domain/usecases/GetNewsNotification'
import RemoveNewsNotification from 'src/domain/usecases/RemoveNewsNotification'
import LocalNotificationRepository from 'src/data/storage/localStorage/NotificationRepository'

interface Props {
  location: WindowLocation
}

const Navbar: React.FC<Props> = props => {
  const { pathname } = props.location

  const [hasNotification, setHasNotification] = useState(false)

  const newsQueryData = useStaticQuery(graphql`
    query {
      allWordpressWpNews(limit: 1, sort: {fields: id, order: ASC}) {
        edges {
          node {
            wordpress_id
          }
        }
      }
    }
  `)

  const latestNewsId = newsQueryData.allWordpressWpNews.edges[0].node.wordpress_id

  useEffect(() => {
    const getNewsNotification = async () => {
      const repository = new LocalNotificationRepository()
      const useCase = new GetNewsNotification(repository)
      const hasNotification = await useCase.execute(latestNewsId)
      setHasNotification(hasNotification)
    }
    getNewsNotification()
  }, [latestNewsId])

  useEffect(() => {
    const isNewsPath = pathname.startsWith('/news')
    if (isNewsPath && hasNotification) {
      const removeNewsNotification = async () => {
        const repository = new LocalNotificationRepository()
        const useCase = new RemoveNewsNotification(repository)
        await useCase.execute(latestNewsId)
        setHasNotification(false)
      }
      removeNewsNotification()
    }
  }, [pathname, hasNotification, latestNewsId])

  return (
    <Wrapper id="nav-section">
      <nav>
        <ul>
          <li>
            <NavLink to="/expositions">
              <TypographyLink>
                ВЫСТАВКИ
              </TypographyLink>
            </NavLink>
          </li>
          <li>
            <NavLink to="/news">
              <TypographyLink badge={hasNotification}>
                НОВОСТИ
              </TypographyLink>
            </NavLink>
          </li>
        </ul>
      </nav>
    </Wrapper>
  )
}

const NavbarWithLocation: React.FC = () => {
  return (
    <Location>
      {(props: LocationContext) => {
        return <Navbar location={props.location} />
      }}
    </Location>
  )
}

export default NavbarWithLocation
