import React from 'react'
import StyledH5 from './style'

interface Props {
  className?: string
  italic?: boolean
  children?: React.ReactNode
  dangerouslySetInnerHTML?: any
}

const H5:React.FC<Props> = props => {
  const { className, italic, children, ...other } = props

  return (
    <StyledH5
      className={className}
      italic={italic}
      {...other}
    >
      {children ?? null}
    </StyledH5>
  )
}

export default H5
