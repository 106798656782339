import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledH2 = styled.h2`
  font-family: ${props => props.theme.fontFamily.secondary};
  color: ${props => props.theme.colors.bodyTextDark};
  font-size: 1.6rem;
  letter-spacing: .2em;
  line-height: 1.74;

  ${up('mobile')} {
    font-size: 1.7rem;
  }

  ${up('desktop')} {
    font-size: 2.3rem;
  }

  ${up('largeDesktop')} {
    font-size: 4.3rem;
  }
`

export default StyledH2
