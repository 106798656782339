import styled from 'styled-components'
import { up } from 'src/utils/media'

const Wrapper = styled.section`
  transition: all .3s ease-in;

  & nav {
    width: auto;
    margin: 0 auto;

    ${up('tablet')} {
      z-index: 3;
    }

    ul {
      list-style: none;
      display: flex;
      margin: 0 auto;
      justify-content: center;

      & li {
        padding: 4rem 3.3rem 1rem 3.3rem;

        ${up('tablet')} {
          padding: 0 4.8rem;
        }

        a > span {
          text-transform: uppercase;
          color: ${props => props.theme.colors.button};
        }

        a.active > span {
          color: ${props => props.theme.colors.bodyTextDark};
        }
      }
    }
  }

`

export default Wrapper
