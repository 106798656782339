import styled from 'styled-components'
import { up } from 'src/utils/media'

const StyledCaption = styled.p`
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.bodyTextDark};
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.35;
  font-weight: 500;
  font-style: italic;

  ${up('mobile')} {
    font-size: 1.5rem;
  }

  ${up('desktop')} {
    font-size: 1.7rem;
  }

  ${up('largeDesktop')} {
    font-size: 2.7rem;
  }
`

export default StyledCaption
