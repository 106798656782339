import styled from 'styled-components'
import { Col } from '@baevra/grid'
import { up } from 'src/utils/media'

const Wrapper = styled(Col)`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 0;
  user-select: none;
  align-items: center;
  margin-bottom: 7rem;
  position: relative;
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
  
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 93%;
    height: auto;
    padding-top: 93%;
    border: 1px solid ${props => props.theme.colors.separator[300]};
    z-index: -1;
    transition: transform 1s ease-in-out;
  }

  ${up('tablet')} {
    &:hover::after {
      transform: translateX(-50%) scale(1.05);
    }
  }

  ${up('mobile')} {
    width: 35rem;
    max-width: 35rem;
    margin-bottom: 7rem;
    margin-left: calc((100% - 70rem) / 4 );
    margin-right: calc((100% - 70rem) / 4 );
  }

  ${up('tablet')} {
    max-width: unset;
    width: calc(400 / 1780 * 100%);
    max-width: calc(400 / 1780 * 100%);
    margin-left: calc((100% - 400 / 1780 * 100% * 3) / 6);
    margin-right: calc((100% - 400 / 1780 * 100% * 3) / 6);
    margin-bottom: 14rem;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  ${up('desktop')} {
    margin-bottom: 14rem;
  }

  ${up('largeDesktop')} {
    margin-bottom: 25rem;
  }

  & > div {
    text-align: center;
    margin-bottom: 3rem;
    word-break: break-word;
    overflow: hidden;

    ${up('tablet')} {
      margin-bottom: 2.7rem;
    }

    ${up('desktop')} {
      margin-bottom: 5rem;
      /* margin-bottom: calc(100vw / 1920 * 50); */
    }

    ${up('largeDesktop')} {
      margin-bottom: 6rem;
    }
  }

  .subtitle {
    white-space: nowrap;

    ${up('tablet')} {
      max-width: 36rem;
      height: 2.5rem;
    }

    ${up('largeDesktop')} {
      max-width: 60rem;
      height: 6rem;
    }
  }

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: visible;
    justify-content: center;
    margin-top: auto;

    ${up('tablet')} {
      height: 9rem;
      margin-top: 0;
    }

    ${up('desktop')} {
      height: calc(100vw / 1920 * 70);
    }

    ${up('largeDesktop')} {
      height: calc(100vw / 1920 * 70);
    }
    
    & a {
      transition: all 1s ease-in-out;
      transform: scale(1);

      &:hover {
        transform: scale(1.05);
        color: ${props => props.theme.colors.button};
      }
    }

    & h2 {
      text-transform: uppercase;
      color: inherit;
      text-align: center;
    }
    
  }

  .caption {
    display: flex;
    align-items: center;
    max-width: 80%;
    white-space: normal;
    margin-bottom: 3.5rem!important;

    ${up('tablet')} {
      max-width: 80%;
      height: 5rem;
      margin-bottom: calc(100vw / 1920 * 35)!important;
    }

    ${up('desktop')} {
      max-width: 70%;
      height: 5rem;
    }

    ${up('largeDesktop')} {
      height: 7.5rem;
    }
  }

  .poster {
    display: flex;
    width: 75%;
    height: auto;
    max-width: 30rem;
    max-height: 30rem;
    overflow: hidden;
    position: relative;
    user-select: none;
    margin: 0 auto 3.5rem auto;
    margin-top: auto;

    ${up('tablet')} {
      max-width: unset;
      max-height: unset;
      margin-bottom: 3rem;
      margin-top: 0;
    }

    ${up('desktop')} {
      margin-bottom: 4rem;
    }

    ${up('largeDesktop')} {
      margin-bottom: 10rem;
    }

    a {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 100%;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        object-fit: cover;
        width: 100%;
        height: auto;
        min-width: 100%;
        min-height: 100%;
        transition: transform 2s ease-out;
        user-select: none;
      }

      &:hover img {
        transform: translate(-50%,-50%) scale(1.05);
      }
    }

  }
`

export default Wrapper
