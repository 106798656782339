import React from 'react'
import { Link } from 'gatsby'
import H2 from 'src/components/Typography/H2'
import H5 from 'src/components/Typography/H5'
import Caption from 'src/components/Typography/Caption'
import { CardProps } from 'src/components/Card'
import Wrapper from './style'

const ExpositionCard = (props: CardProps) => {
  const { title, subtitle, caption, image, slug } = props.data
  const { className } = props
  const link = `/expositions/${slug}`

  return (
    <Wrapper className={className}>
      <div className="subtitle">
        <H5 italic dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
      <div className="title">
        <Link to={link}>
          <H2 dangerouslySetInnerHTML={{ __html: title }} />
        </Link>
      </div>
      <div className="poster">
        <Link to={link}>
          <img src={image} alt="poster" />
        </Link>
      </div>
      <div className="caption">
        <Caption>{caption ?? ''}</Caption>
      </div>
    </Wrapper>
  )
}

export default ExpositionCard
