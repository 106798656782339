import React from 'react'
import StyledCaption from './style'

interface Props {
  className?: string
  children?: React.ReactNode
  dangerouslySetInnerHTML?: any
}

const Caption:React.FC<Props> = props => {
  const { className, children, dangerouslySetInnerHTML } = props
  return (
    <StyledCaption
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML || { __html: children }}
   />
  )
}

export default Caption
