import styled from 'styled-components'
import { up } from 'src/utils/media'

interface Props {
  italic?: boolean
}

const StyledH5 = styled.h5<Props>`
  font-family: ${props => props.theme.fontFamily.primary};
  color: ${props => props.theme.colors.bodyTextDark};
  font-size: 1.7rem;
  line-height: 1.24;
  font-weight: 500;
  font-style: ${props => props.italic ? 'italic' : 'normal'};

  ${up('mobile')} {
    font-size: 1.7rem;
  }

  ${up('desktop')} {
    font-size: 2.1rem;
  }

  ${up('largeDesktop')} {
    font-size: 4.8rem;
  }
`

export default StyledH5
