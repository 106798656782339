import React, { useState, useEffect, useCallback } from 'react'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
  minimum: 0.0,
  showSpinner: false,
  trickle: false,
  easing: 'ease-out',
  speed: 700
})

interface Props {
  // horizontalConfig?: {
  //   menuScroll: number,
  //   totalScroll: number
  // }
  isHorizontal?: boolean
  menuScroll?: number,
  totalScroll?: number
}

type PercentValue = number

const ProgressBar: React.FC<Props> = props => {
  const { isHorizontal, menuScroll, totalScroll } = props
  const isVertical = !isHorizontal

  const [scrollPercentValue, setScrollPercentValue] = useState<PercentValue>(0)

  const listener = useCallback(() => {
    if (!isVertical) { return }

    const documentHeight = document?.body.clientHeight - window?.innerHeight ?? 0
    const scrollY = window?.scrollY ?? 0
    const scrollPercentValue = Math.round(scrollY/documentHeight * 100)
    setScrollPercentValue(scrollPercentValue)
  }, [isVertical])

  useEffect(() => {
    if (!isHorizontal) { return }

    const progress = menuScroll ?? 0
    const total = totalScroll ?? 0
    const scrollPercentValue = Math.min(progress/total * 100, 100)
    setScrollPercentValue(scrollPercentValue)
  }, [menuScroll, totalScroll, isHorizontal])

  useEffect(() => {
    listener()
    window?.addEventListener('scroll', listener)
    return () => {
      window?.removeEventListener('scroll', listener)
    }
  }, [listener])

  useEffect(() => {
    NProgress.start()
    return () => {
      NProgress.done()
    }
  }, [])

  useEffect(() => {
    const progress = Math.min(scrollPercentValue * 0.01, 0.999999)
    NProgress.set(progress)
  }, [scrollPercentValue])

  return null
}

ProgressBar.defaultProps = {
  isHorizontal: false
}

export default ProgressBar
