import React from 'react'
import { graphql } from 'gatsby'
import ProgressBar from 'src/components/ProgressBar'
import Navbar from 'src/sections/Navbar'
import ExpositionsList from 'src/sections/ExpositionsList'
import Layout from 'src/layout'
import removeHTMLTags from 'src/utils/removeHTMLTags'
import SEO from 'src/components/SEO'

const createExpositions = (wordpressExposition: any) => {
  return wordpressExposition.edges
    .map((edge: any) => {
      const exposition = edge.node
      const title = removeHTMLTags(exposition.title)
      const subtitle = exposition.acf.authors
      const caption = exposition.acf.about
      const slug = exposition.slug
      const image = exposition.better_featured_image?.source_url

      return {
        title,
        subtitle,
        caption,
        slug,
        image
      }
    })
}

const ExpositionsPage = (props: any) => {
  const expositions = createExpositions(props.data.allWordpressWpExpositions)

  return (
    <Layout>
      <SEO url={props.location.href} />
      <ProgressBar />
      <main>
        <Navbar />
        <ExpositionsList data={expositions} />
      </main>
    </Layout>
  )
}

export default ExpositionsPage

export const query = graphql`
  query {
    allWordpressWpExpositions {
      edges {
        node {
          title
          slug
          acf {
            authors
            about
          }
          better_featured_image {
            source_url
            alt_text
          }
        }
      }
    }
  }
`
