import React from 'react'
import StyledH2 from './style'

interface Props {
  className?: string
  dark?: boolean
  children?: React.ReactNode
  dangerouslySetInnerHTML?: any
}

const H2:React.FC<Props> = props => {
  const { className, children, ...other } = props

  return (
    <StyledH2
      className={className}
      {...other}
    >
    {children ?? null}
    </StyledH2>
  )
}

export default H2
